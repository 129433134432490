import * as CSS from 'csstype'
import { createStyles } from 'utils/css'

interface IContentScrollStyles {
  maxHeight: string
  styles?: CSS.Properties
}

export const root = ({ maxHeight, styles }: IContentScrollStyles) =>
  createStyles(({ colors, radii, spacing }) => ({
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: spacing(1.5),
    maxHeight: `calc(${maxHeight} - 60px)`,
    '&::-webkit-scrollbar': {
      width: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: colors.surface200,
      borderRadius: radii.md
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.surface300,
      borderRadius: radii.md,
      ['&::-webkit-scrollbar-thumb:hover' as CSS.Pseudos]: {
        backgroundColor: colors.surface400
      }
    },
    ...styles
  }))
